@import "~@/styles/variables/variables.scss";













































































































































































































































































































































































































































.device-account {
	@include base-button(80px);
	height: 100%;
	//display: flex;
	//flex-direction: column;
	//align-content: flex-start;
	.content {
		//flex: 1;
		display: flex;
		flex-direction: column;
		height: calc(100% - 73px);
		box-sizing: border-box;
		padding: 20px 20px 0 24px;
		.filter {
			display: flex;
			flex-wrap: wrap;
			.search {
				margin-bottom: 14px;
				margin-right: 20px;
			}
			.btn-ops {
				@include base-button;
			}
		}
		.table {
			flex: 1;
			height: 0;
		}
	}
}
